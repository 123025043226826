.wallet-adapter-modal-wrapper, .wallet-adapter-modal-button-close {
    background-color: #121212;
}
.wallet-adapter-button {
    line-height: 40px;
    height: 40px;
}
.wallet-adapter-button-start-icon {
    display: none;
}

#wrapper::before {
    content: '';
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background: url('./../public/bg2.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.7;
}